<template>
  <router-view/>
</template>


<script setup>


</script>

<style>

html,body,#app{
  height: 100%;
}

.app-container{
  padding-top: 20px;
}

</style>


